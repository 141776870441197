import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Group, Text, Input, Button } from '../components';
import { useTranslation } from 'react-i18next';

const InputDialogDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const inputDialog_buttons_positiveOnClick = (options) => {
		run([{"id":"do1676988242106","function":"do","comments":"new_method","if":{"what":"@property.action","is":"new_method"},"what":[{"id":"request1676988242106","function":"request","url":"(@cookie.api_url)\/providers\/(@cookie.providerName)\/methods","headers":{"Authorization":"Bearer (@cookie.bearer)"},"method":"post","into":"@database.method","response":"json-api","params":{"name":"@property.inputMethod","reference":false,"description":"{\"en\":\"\",\"es\":\"\"}"},"onsuccess":[{"id":"request1676999960922","function":"request","url":"(@cookie.api_url)\/providers\/(@database.provider.name)","headers":{"Authorization":"Bearer (@cookie.bearer)"},"method":"get","into":"@database.provider","response":"json-api","onsuccess":[]},{"id":"set1676988242106","function":"set","what":"@cookie.methodID","value":"@database.method.uid"},{"id":"goto1676988242106","function":"goto","view":"api_provider_method"}],"onerror":[{"id":"popup1677142557766","function":"popup","dialog":"error"}]}]},{"id":"do1676935635309","function":"do","comments":"new_provider","if":{"what":"@property.action","is":"new_provider"},"what":[{"id":"request1676935899854","function":"request","url":"(@cookie.api_url)\/providers","headers":{"Authorization":"Bearer (@cookie.bearer)"},"method":"post","into":"@database.provider","response":"json-api","params":{"name":"@property.input","label":"@property.input"},"onsuccess":[{"id":"set1676935980642","function":"set","what":"@cookie.providerID","value":"@database.provider.uid"},{"id":"goto1676935996556","function":"goto","view":"api_provider"}]}]},{"function":"hide","dialog":"inputDialog"}], options)
	}
	const inputDialog_buttons_negativeOnClick = (options) => {
		run([{"function":"hide","dialog":"inputDialog"}], options)
	}
	const inputDialogOnLoaded = (options) => {
		run([], options)
	}

  return (
    
    
			<Modal id="inputDialog" onShow={inputDialogOnLoaded}>
					<Modal.Body>
						<Group
							id="newProviderGroup"
							if={{"what":"@property.action","is":"new_provider"}}
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Text
								id="text1676935528663"
								className="w-100 h-auto position-relative" >
								{t('inputDialog.text1676935528663')}
							</Text>
							<Input.Text
								id="inputtext1676935561932"
								name="text"
								src="@property.input"
							autoComplete="off"
								className="w-100 h-auto position-relative mt-16px" />
						</Group>
						<Group
							id="newMethodGroup"
							if={{"what":"@property.action","is":"new_method"}}
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Text
								id="text1676988195576"
								className="w-100 h-auto position-relative" >
								{t('inputDialog.text1676988195576')}
							</Text>
							<Input.Text
								id="inputtext1676988195576"
								name="text"
								src="@property.inputMethod"
							autoComplete="off"
								className="w-100 h-auto position-relative mt-16px" />
						</Group>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="inputDialog_buttons_negative"
							onClick={inputDialog_buttons_negativeOnClick}
							variant="transparent"
							className="w-auto h-auto position-relative" >
							{t('inputDialog.inputDialog_buttons_negative')}
						</Button>
						<Button
							id="inputDialog_buttons_positive"
							onClick={inputDialog_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('inputDialog.inputDialog_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default InputDialogDialog;
