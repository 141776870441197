import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Main_wrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Image, Text, Icon, Input } from '../components';
import isotype from '../assets/ico/isotype.svg';
import { useTranslation } from 'react-i18next';

export const Api_provider_methodView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const saveProviderButton1676980936980OnClick = (options) => {
		run([{"id":"set1677023586026","function":"set","what":"@property.action","value":"save_method"},{"id":"request1677023586026","function":"request","url":"(@cookie.api_url)\/providers\/(@database.provider.name)\/methods\/(@database.method.name)","headers":{"Authorization":"Bearer (@cookie.bearer)"},"method":"patch","into":"@database.method","response":"json-api","params":{"name":"@database.method.name","config":"@database.method.config","reference":"@database.method.reference","urlHelp":"@database.method.urlHelp","description":"@database.method.description"},"onsuccess":[{"id":"popup1677023586026","function":"popup","dialog":"success"}],"onerror":[{"id":"popup1677023586026","function":"popup","dialog":"error"}]}], options)
	}
	const methodBackButtonOnClick = (options) => {
		run([{"id":"back1676980936980","function":"back"}], options)
	}
	const image1676994804572OnClick = (options) => {
		run([{"id":"goto1676994804572","function":"goto","view":"home"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('api_provider_method.title')}</title>
      </Helmet>
      <Main_wrapperWrapper>
          
			<Group
				id="methodBody"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative scrollable" >
				<Group
					id="group1676994804572"
					direction="horizontal"
					className="w-100 h-auto position-relative bg-light p-24px" >
					<Image
						id="image1676994804572"
						onClick={image1676994804572OnClick}
						src={isotype}
						className="w-48px h-auto position-relative" />
					<Text
						id="text1676994804572"
						tag="h3"
						className="w-100 h-auto valign-center position-relative ms-24px ff-titles" >
						{t('api_provider_method.text1676994804572')}
					</Text>
				</Group>
				<Group
					id="mehtodGroup"
					direction="vertical"
					className="w-100 h-auto position-relative p-48px" >
					<Group
						id="methodTitleGroup"
						direction="horizontal"
						className="w-auto h-auto position-relative" >
						<Icon
							id="methodBackButton"
							onClick={methodBackButtonOnClick}
							src="arrow-left-thick"
							className="w-auto h-auto valign-center position-relative"
						/>
						<Text
							id="methodName"
							tag="h1"
							className="w-auto h-auto valign-center position-relative ms-16px ff-titles" >
							{t('api_provider_method.methodName')}
						</Text>
						<Text
							id="saveProviderButton1676980936980"
							onClick={saveProviderButton1676980936980OnClick}
							tag="small"
							className="w-auto h-auto border-radius-50px valign-center position-relative bg-accent ps-12px pe-16px py-6px ms-24px" >
							{t('api_provider_method.saveProviderButton1676980936980')}
						</Text>
					</Group>
					<Group
						id="providerInfo1676980936980"
						direction="vertical"
						className="w-100 h-auto position-relative mt-32px" >
						<Input.Switch
							id="methodReferenceInput"
							label={t('api_provider_method.methodReferenceInput.label')}
							name="switch"
							src="@database.method.reference"
							className="w-auto h-auto position-relative mt-16px" />
						<Input.TextArea
							id="providerDescriptionInput1676980936980"
							label={t('api_provider_method.providerDescriptionInput1676980936980.label')}
							name="text"
							src="@database.method.description"
						autoComplete="off"
							className="w-100 h-auto position-relative mt-16px" />
						<Input.TextArea
							id="methodConfigInput"
							label={t('api_provider_method.methodConfigInput.label')}
							name="text"
							src="@database.method.config"
						autoComplete="off"
							className="w-100 h-auto position-relative mt-16px" />
						<Input.Text
							id="methodUrlHelpInput"
							label={t('api_provider_method.methodUrlHelpInput.label')}
							name="text"
							src="@database.mehtod.urlHelp"
						autoComplete="off"
							className="w-400px h-auto position-relative mt-16px" />
					</Group>
				</Group>
			</Group>
      </Main_wrapperWrapper>
    </>
  );
};
