import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Button } from '../components';
import { useTranslation } from 'react-i18next';

const SuccessDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const success_buttons_positiveOnClick = (options) => {
		run([{"function":"hide","dialog":"success"}], options)
	}

  return (
    
    
			<Modal id="success">
					<Modal.Header>
						<Modal.Title>{t('success.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Text
							id="saveProviderSuccess"
							if={{"what":"@property.action","is":"save_provider"}}
							className="w-100 h-auto position-relative" >
							{t('success.saveProviderSuccess')}
						</Text>
						<Text
							id="saveProviderSuccess1677581277562"
							if={{"what":"@property.action","is":"save_method"}}
							className="w-100 h-auto position-relative" >
							{t('success.saveProviderSuccess1677581277562')}
						</Text>
						<Text
							id="deleteMethodSuccess"
							if={{"what":"@property.action","is":"delete_method"}}
							className="w-100 h-auto position-relative" >
							{t('success.deleteMethodSuccess')}
						</Text>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="success_buttons_positive"
							onClick={success_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('success.success_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default SuccessDialog;
