import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Button } from '../components';
import { useTranslation } from 'react-i18next';

const ErrorDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const error_buttons_positiveOnClick = (options) => {
		run([{"function":"hide","dialog":"error"}], options)
	}

  return (
    
    
			<Modal id="error">
					<Modal.Header>
						<Modal.Title>{t('error.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Text
							id="authError"
							if={{"what":"@property.action","is":"auth"}}
							className="w-100 h-auto position-relative" >
							{t('error.authError')}
						</Text>
						<Text
							id="unauthorized"
							if={{"what":"@property.action","is":"authorization"}}
							className="w-100 h-auto position-relative" >
							{t('error.unauthorized')}
						</Text>
						<Text
							id="saveError"
							if={{"what":"@property.action","is":"save_provider"}}
							className="w-100 h-auto position-relative" >
							{t('error.saveError')}
						</Text>
						<Text
							id="saveError1677581295095"
							if={{"what":"@property.action","is":"save_method"}}
							className="w-100 h-auto position-relative" >
							{t('error.saveError1677581295095')}
						</Text>
						<Text
							id="deleteMethod"
							if={{"what":"@property.action","is":"delete_method"}}
							className="w-100 h-auto position-relative" >
							{t('error.deleteMethod')}
						</Text>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="error_buttons_positive"
							onClick={error_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('error.error_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default ErrorDialog;
