import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Button } from '../components';
import { useTranslation } from 'react-i18next';

const ConfirmationDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const confirmation_buttons_positiveOnClick = (options) => {
		run([{"id":"do1676980233847","function":"do","comments":"delete_method","if":{"what":"@property.action","is":"delete_method"},"what":[{"id":"set1677142176765","function":"set","what":"@property.action","value":"delete_method"},{"id":"request1676980233847","function":"request","url":"(@cookie.api_url)\/providers\/(@cookie.providerName)\/methods\/(@cookie.methodName)","headers":{"Authorization":"Bearer (@cookie.bearer)"},"method":"delete","into":"@database.response","response":"json-api","onsuccess":[{"id":"request1677142254240","function":"request","url":"(@cookie.api_url)\/providers\/(@cookie.providerName)","headers":{"Authorization":"Bearer (@cookie.bearer)"},"method":"get","into":"@database.provider","response":"json-api","onsuccess":[]}],"onerror":[{"id":"popup1677142196533","function":"popup","dialog":"confirmation"}]}]},{"function":"hide","dialog":"confirmation"}], options)
	}
	const confirmation_buttons_negativeOnClick = (options) => {
		run([{"function":"hide","dialog":"confirmation"}], options)
	}
	const confirmationOnLoaded = (options) => {
		run([], options)
	}

  return (
    
    
			<Modal id="confirmation" onShow={confirmationOnLoaded}>
					<Modal.Body>
						<Text
							id="deleteMethodConfirmation"
							className="w-100 h-auto position-relative" >
							{t('confirmation.deleteMethodConfirmation')}
						</Text>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="confirmation_buttons_negative"
							onClick={confirmation_buttons_negativeOnClick}
							variant="transparent"
							className="w-auto h-auto position-relative" >
							{t('confirmation.confirmation_buttons_negative')}
						</Button>
						<Button
							id="confirmation_buttons_positive"
							onClick={confirmation_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('confirmation.confirmation_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default ConfirmationDialog;
