import React from 'react';

// Custom imports:
import Auth_dialogDialog from './Auth_dialogDialog';
import Auth_termsDialog from './Auth_termsDialog';
import ConfirmationDialog from './ConfirmationDialog';
import ErrorDialog from './ErrorDialog';
import InputDialogDialog from './InputDialogDialog';
import SuccessDialog from './SuccessDialog';



export const Dialogs = () => {
  return (
    <>
      
			<Auth_dialogDialog />
			<Auth_termsDialog />
			<ConfirmationDialog />
			<ErrorDialog />
			<InputDialogDialog />
			<SuccessDialog />
      
    </>
  );
};

export default Dialogs;
