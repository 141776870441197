import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Group, Input, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Auth_dialogDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const auth_dialog_buttons_positiveOnClick = (options) => {
		run([{"function":"login","provider":"firebase.email","forgot":true,"if":{"what":"@property.authMessage","is":"forgot"},"email":"@property.authEmail","oninvalid":[{"function":"set","what":"@property.authMessage","value":"recoveryError"},{"function":"popup","dialog":"auth_dialog"}],"onvalid":[{"function":"set","what":"@property.authMessage","value":"recoverySent"},{"function":"popup","dialog":"auth_dialog"}]},{"function":"hide","dialog":"auth_dialog"}], options)
	}

  return (
    
    
			<Modal id="auth_dialog" centered>
					<Modal.Header>
						<Modal.Title>{t('auth_dialog.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Text
							id="authMessageGoogle"
							if={{"what":"@property.authMessage","is":"google"}}
							className="w-100 h-auto position-relative" >
							{t('auth_dialog.authMessageGoogle')}
						</Text>
						<Text
							id="authMessageSigninError"
							if={{"what":"@property.authMessage","is":"signin"}}
							className="w-100 h-auto position-relative" >
							{t('auth_dialog.authMessageSigninError')}
						</Text>
						<Text
							id="authMessageSignupError"
							if={{"what":"@property.authMessage","is":"signup"}}
							className="w-100 h-auto position-relative" >
							{t('auth_dialog.authMessageSignupError')}
						</Text>
						<Text
							id="authMessageSignupExists"
							if={{"what":"@property.authMessage","is":"exists"}}
							className="w-100 h-auto position-relative" >
							{t('auth_dialog.authMessageSignupExists')}
						</Text>
						<Text
							id="authMessageSignupWeak"
							if={{"what":"@property.authMessage","is":"weak"}}
							className="w-100 h-auto position-relative" >
							{t('auth_dialog.authMessageSignupWeak')}
						</Text>
						<Text
							id="authMessageTermsMandatory"
							if={{"what":"@property.authMessage","is":"terms"}}
							className="w-100 h-auto position-relative" >
							{t('auth_dialog.authMessageTermsMandatory')}
						</Text>
						<Group
							id="authMessageForgot"
							if={{"what":"@property.authMessage","is":"forgot"}}
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Text
								id="authForgotEmailInstructions"
								className="w-100 h-auto position-relative ff-auth" >
								{t('auth_dialog.authForgotEmailInstructions')}
							</Text>
							<Input.Text
								id="authForgotEmailInput"
								name="text"
								src="@property.authEmail"
							autoComplete="off"
								className="w-100 h-auto position-relative mt-24px" />
						</Group>
						<Text
							id="authMessageRecoveryError"
							if={{"what":"@property.authMessage","is":"recoveryError"}}
							className="w-100 h-auto position-relative text-primary-text ff-auth" >
							{t('auth_dialog.authMessageRecoveryError')}
						</Text>
						<Text
							id="authMessageRecoverySent"
							if={{"what":"@property.authMessage","is":"recoverySent"}}
							className="w-100 h-auto position-relative text-primary-text ff-auth" >
							{t('auth_dialog.authMessageRecoverySent')}
						</Text>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="auth_dialog_buttons_positive"
							onClick={auth_dialog_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('auth_dialog.auth_dialog_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Auth_dialogDialog;
