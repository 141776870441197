import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Main_wrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, Card, Image, Icon } from '../components';
import { useEffect } from 'react';
import ic_api from '../assets/ico/ic_api.svg';
import { useTranslation } from 'react-i18next';

export const HomeView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const homeParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( homeParams, [])
	const homeOnLoaded = (options) => {
		run([{"id":"set1676935750292","function":"set","what":"@cookie.api_url","value":"https:\/\/api.dev.doyo.tech","if":{"what":"@cookie.env","is":"dev","else":[{"id":"set1676935805626","function":"set","what":"@cookie.api_url","value":"https:\/\/api.doyo.tech"}]}}], options)
	}
	useEffect(homeOnLoaded, [])
	const icon1677068984108OnClick = (options) => {
		run([{"id":"goto1677069030049","function":"goto","view":"auth_logout"}], options)
	}
	const cardAPIOnClick = (options) => {
		run([{"id":"set1677022535995","function":"set","what":"@property.action","value":"authorization"},{"id":"request1677022366568","function":"request","url":"(@cookie.api_url)\/user","headers":{"Authorization":"Bearer (@cookie.bearer)"},"method":"get","into":"@database.response","response":"json-api","onsuccess":[{"id":"goto1677022399239","function":"goto","view":"api","if":{"what":"@database.response.is_admin","is":true}},{"id":"popup1677022596406","function":"popup","dialog":"error","if":{"what":"@database.response.is_admin","is_not":true}}],"onerror":[{"id":"popup1677022582555","function":"popup","dialog":"error"}]}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('home.title')}</title>
      </Helmet>
      <Main_wrapperWrapper>
          
			<Group
				id="mainBody"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative p-48px scrollable" >
				<Text
					id="mainTitle"
					tag="h1"
					className="w-100 h-auto position-relative text-primary ff-titles" >
					{t('home.mainTitle')}
				</Text>
				<Group
					id="toolMenuGroup"
					direction="vertical"
					className="w-100 h-auto position-relative mt-48px" >
					<Card
						id="cardAPI"
						onClick={cardAPIOnClick}
						className="w-400px h-auto position-relative" >
						<Card.Body>
								<Image
									id="cardImageAPI"
									src={ic_api}
									className="w-128px h-auto align-center position-relative" />
								<Text
									id="text1676987883652"
									tag="h3"
									className="w-100 h-auto position-relative mt-24px text-primary-dark ff-renner" >
									{t('home.text1676987883652')}
								</Text>
								<Text
									id="text1676987935984"
									className="w-100 h-auto position-relative mt-24px text-secondary-text ff-renner" >
									{t('home.text1676987935984')}
								</Text>
						</Card.Body>
					</Card>
				</Group>
				<Icon
					id="icon1677068984108"
					onClick={icon1677068984108OnClick}
					src="logout"
					className="w-auto h-auto align-end valign-start position-absolute m-16px"
				/>
			</Group>
      </Main_wrapperWrapper>
    </>
  );
};
