import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Main_wrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Image, Text, Icon, Input, List } from '../components';
import isotype from '../assets/ico/isotype.svg';
import { useTranslation } from 'react-i18next';

export const Api_providerView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const methodDeleteButton1676980891964OnClick = (options) => {
		run([{"id":"set1676980891964","function":"set","what":"@cookie.methodID","value":"@field.uid"},{"id":"set1676980891964","function":"set","what":"@cookie.methodName","value":"@field.name"},{"id":"request1676988399078","function":"request","url":"(@cookie.api_url)\/providers\/(@cookie.providerName)\/methods\/(@cookie.methodName)","headers":{"Authorization":"Bearer (@cookie.bearer)"},"method":"get","into":"@database.method","response":"json-api","onsuccess":[{"id":"goto1676988407108","function":"goto","view":"api_provider_method"}]}], options)
	}
	const methodDeleteButtonOnClick = (options) => {
		run([{"id":"set1676980731407","function":"set","what":"@cookie.methodID","value":"@field.uid"},{"id":"set1676980764590","function":"set","what":"@cookie.methodName","value":"@field.name"},{"id":"set1676980219546","function":"set","what":"@property.action","value":"delete_method"},{"id":"popup1676980262523","function":"popup","dialog":"confirmation"}], options)
	}
	const addProviderMethodButtonOnClick = (options) => {
		run([{"id":"set1676988152177","function":"set","what":"@property.action","value":"new_method"},{"id":"popup1676988152177","function":"popup","dialog":"inputDialog"}], options)
	}
	const saveProviderButtonOnClick = (options) => {
		run([{"id":"set1677023530423","function":"set","what":"@property.action","value":"save_provider"},{"id":"request1676976338123","function":"request","url":"(@cookie.api_url)\/providers\/(@database.provider.name)","headers":{"Authorization":"Bearer (@cookie.bearer)"},"method":"patch","into":"@database.provider","response":"json-api","params":{"name":"@database.provider.name","label":"@database.provider.label","description":"@database.provider.description","headline":"@database.provider.headline","config":"@database.provider.config","url":"@database.provider.url","urlLogo":"@database.provider.urlLogo","urlHelp":"@database.provider.help"},"onsuccess":[{"id":"popup1677023510425","function":"popup","dialog":"success"}],"onerror":[{"id":"popup1677023523033","function":"popup","dialog":"error"}]}], options)
	}
	const providerBackButtonOnClick = (options) => {
		run([{"id":"back1676934303206","function":"back"}], options)
	}
	const image1676994792754OnClick = (options) => {
		run([{"id":"goto1676994792754","function":"goto","view":"home"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('api_provider.title')}</title>
      </Helmet>
      <Main_wrapperWrapper>
          
			<Group
				id="apiBody1676934135713"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative scrollable" >
				<Group
					id="group1676994792754"
					direction="horizontal"
					className="w-100 h-auto position-relative bg-light p-24px" >
					<Image
						id="image1676994792754"
						onClick={image1676994792754OnClick}
						src={isotype}
						className="w-48px h-auto position-relative" />
					<Text
						id="text1676994792754"
						tag="h3"
						className="w-100 h-auto valign-center position-relative ms-24px ff-titles" >
						{t('api_provider.text1676994792754')}
					</Text>
				</Group>
				<Group
					id="providerGroup"
					direction="vertical"
					className="w-100 h-auto position-relative p-48px" >
					<Group
						id="providerTitleGroup"
						direction="horizontal"
						className="w-auto h-auto position-relative" >
						<Icon
							id="providerBackButton"
							onClick={providerBackButtonOnClick}
							src="arrow-left-thick"
							className="w-auto h-auto valign-center position-relative"
						/>
						<Text
							id="providerTitle"
							tag="h1"
							className="w-auto h-auto valign-center position-relative ms-16px ff-titles" >
							{t('api_provider.providerTitle')}
						</Text>
						<Text
							id="saveProviderButton"
							onClick={saveProviderButtonOnClick}
							tag="small"
							className="w-auto h-auto border-radius-50px valign-center position-relative bg-accent ps-12px pe-16px py-6px ms-24px" >
							{t('api_provider.saveProviderButton')}
						</Text>
					</Group>
					<Group
						id="providerInfo"
						direction="vertical"
						className="w-100 h-auto position-relative mt-32px" >
						<Input.Text
							id="providerLabelInput"
							label={t('api_provider.providerLabelInput.label')}
							name="text"
							src="@database.provider.label"
						autoComplete="off"
							className="w-auto h-auto position-relative" />
						<Input.TextArea
							id="providerHeadlineInput1676976088712"
							label={t('api_provider.providerHeadlineInput1676976088712.label')}
							name="text"
							src="@database.provider.headline"
						autoComplete="off"
							className="w-600px h-auto position-relative" />
						<Input.TextArea
							id="providerDescriptionInput"
							label={t('api_provider.providerDescriptionInput.label')}
							name="text"
							src="@database.provider.description"
						autoComplete="off"
							className="w-100 h-auto position-relative mt-16px" />
						<Input.TextArea
							id="providerConfigInput"
							label={t('api_provider.providerConfigInput.label')}
							name="text"
							src="@database.provider.config"
						autoComplete="off"
							className="w-100 h-auto position-relative mt-16px" />
						<Input.Text
							id="providerUrlInput"
							label={t('api_provider.providerUrlInput.label')}
							name="text"
							src="@database.provider.url"
						autoComplete="off"
							className="w-400px h-auto position-relative mt-16px" />
						<Input.Text
							id="providerUrlLogoInput"
							label={t('api_provider.providerUrlLogoInput.label')}
							name="text"
							src="@database.provider.urlLogo"
						autoComplete="off"
							className="w-400px h-auto position-relative mt-16px" />
						<Input.Text
							id="providerUrlLogoInput1676935183047"
							label={t('api_provider.providerUrlLogoInput1676935183047.label')}
							name="text"
							src="@database.provider.urlHelp"
						autoComplete="off"
							className="w-400px h-auto position-relative mt-16px" />
					</Group>
					<Group
						id="methodsGroup"
						direction="vertical"
						className="w-100 h-auto position-relative mt-32px" >
						<Group
							id="methodsHeader"
							direction="horizontal"
							className="w-100 h-auto position-relative bg-primary" >
							<Text
								id="providerTitle1676934691421"
								tag="h3"
								className="w-auto h-auto border-radius-5px valign-center position-relative bg-primary p-8px text-text-on-primary ff-titles" >
								{t('api_provider.providerTitle1676934691421')}
							</Text>
							<Icon
								id="addProviderMethodButton"
								onClick={addProviderMethodButtonOnClick}
								src="plus-circle"
								color="var(--text-on-primary)"
								className="w-auto h-auto valign-center position-relative ms-16px"
							/>
						</Group>
						<List
							id="methodList"
							direction="vertical"
							src="@database.provider.methods"
							order={{"type":"asc","field":"name"}}
							className="w-100 h-auto position-relative mt-8px" >
							<Group
								id="methodRow"
								direction="horizontal"
								className="w-100 h-auto border-gray border-bottom position-relative" >
								<Group
									id="methodNameGroup"
									direction="vertical"
									className="w-200px h-auto position-relative p-4px" >
									<Text
										id="methodName"
										className="w-100 h-auto position-relative ff-regular" >
										{t('api_provider.methodName')}
									</Text>
								</Group>
								<Group
									id="methodDescriptionGroup"
									direction="vertical"
									className="w-600px h-auto position-relative p-4px" >
									<Text
										id="methodDescription"
										className="w-100 h-auto position-relative ff-regular" >
										{t('api_provider.methodDescription')}
									</Text>
								</Group>
								<Group
									id="group1676980130978"
									direction="vertical"
									className="w-fill h-auto position-relative" >
								</Group>
								<Group
									id="methodRowActions"
									direction="horizontal"
									className="w-auto h-auto position-relative p-4px" >
									<Icon
										id="methodDeleteButton"
										onClick={methodDeleteButtonOnClick}
										src="trash-can"
										className="w-auto h-auto position-relative"
									/>
									<Icon
										id="methodDeleteButton1676980891964"
										onClick={methodDeleteButton1676980891964OnClick}
										src="pencil"
										className="w-auto h-auto position-relative ms-8px"
									/>
								</Group>
							</Group>
						</List>
					</Group>
				</Group>
			</Group>
      </Main_wrapperWrapper>
    </>
  );
};
