import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Auth_fullscreenWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Image, Input, Text } from '../components';
import { useEffect } from 'react';
import isotype from '../assets/ico/isotype.svg';
import { useTranslation } from 'react-i18next';

export const AuthView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const authParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( authParams, [])
	const authOnLoaded = (options) => {
		run([{"id":"set1677022740490","function":"set","what":"@property.url","value":"https:\/\/passport.dev.doyo.tech\/","if":{"what":"@cookie.env","is":"dev","else":[{"id":"set1677022740490","function":"set","what":"@property.url","value":"https:\/\/passport.doyo.tech\/"}]}}], options)
	}
	useEffect(authOnLoaded, [])
	const loginButtonProdOnClick = (options) => {
		run([{"id":"set1677679129335","function":"set","what":"@property.action","value":"auth"},{"id":"request1677679129335","function":"request","url":"https:\/\/passport.doyo.tech\/","method":"post","params":{"operationName":"CreateAuthentication","variables":{"identifier":"@property.authEmail","password":"@property.authPassword"},"query":"mutation CreateAuthentication($identifier: String!, $password: String!) {\ncreateAuthentication(input: {identifier: $identifier, password: $password}) {\nid\ntoken\n__typename\n}\n}"},"onsuccess":[{"id":"do1677679129335","function":"do","if":{"what":"@database.response.data.createAuthentication.token","is":null},"what":[{"id":"popup1677679129335","function":"popup","dialog":"error"}],"comments":"error"},{"id":"do1677679129335","function":"do","if":{"what":"@database.response.data.createAuthentication.token","is_not":null},"what":[{"id":"set1677679129335","function":"set","what":"@cookie.env","value":"prod"},{"id":"set1677679129335","function":"set","what":"@cookie.bearer","value":"@database.response.data.createAuthentication.token"},{"id":"set1677679129335","function":"set","what":"@cookie.__sid","value":"@cookie.bearer"},{"function":"goto","view":"home"}]}],"into":"@database.response","response":"json-api","onerror":[{"id":"popup1677679129335","function":"popup","dialog":"error"}]}], options)
	}
	const loginButtonOnClick = (options) => {
		run([{"id":"set1677022497151","function":"set","what":"@property.action","value":"auth"},{"id":"request1677021961249","function":"request","url":"https:\/\/passport.dev.doyo.tech\/","method":"post","params":{"operationName":"CreateAuthentication","variables":{"identifier":"@property.authEmail","password":"@property.authPassword"},"query":"mutation CreateAuthentication($identifier: String!, $password: String!) {\ncreateAuthentication(input: {identifier: $identifier, password: $password}) {\nid\ntoken\n__typename\n}\n}"},"onsuccess":[{"id":"do1677022113763","function":"do","if":{"what":"@database.response.data.createAuthentication.token","is":null},"what":[{"id":"popup1677022147145","function":"popup","dialog":"error"}],"comments":"error"},{"id":"do1677022157241","function":"do","if":{"what":"@database.response.data.createAuthentication.token","is_not":null},"what":[{"id":"set1677022172002","function":"set","what":"@cookie.env","value":"dev"},{"id":"set1677679115270","function":"set","what":"@cookie.bearer","value":"@database.response.data.createAuthentication.token"},{"id":"set1677029068974","function":"set","what":"@cookie.__sid","value":"@cookie.bearer"},{"function":"goto","view":"home"}]}],"into":"@database.response","response":"json-api","onerror":[{"id":"popup1677022096188","function":"popup","dialog":"error"}]}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('auth.title')}</title>
      </Helmet>
      <Auth_fullscreenWrapper>
          
			<Group
				id="authBody"
				direction="vertical"
				className="w-100 min-h-100vh h-100vh position-relative" >
				<Group
					id="authWrapper"
					direction="horizontal"
					className="min-w-320px w-fill min-h-100 fg-100 position-relative py-32px" >
					<Group
						id="authArea"
						direction="vertical"
						className="w-100 h-auto valign-center position-relative" >
						<Group
							id="authForms"
							direction="vertical"
							className="max-w-400px w-fill h-auto align-center valign-center position-absolute" >
							<Group
								id="signinForm"
								if={{"what":"@property.authForm","is_not":"signup"}}
								direction="vertical"
								className="w-100 h-auto border-radius-15px position-relative p-32px mt-24px" >
								<Image
									id="image1677022058431"
									src={isotype}
									className="w-96px h-auto align-center position-relative" />
								<Group
									id="authSigninEmail"
									direction="vertical"
									className="w-100 h-auto position-relative mt-32px" >
									<Input.Email
										id="authLoginEmailInput"
										name="loginEmail"
										src="@property.authEmail"
									placeholder={t('auth.authLoginEmailInput.hint')}
									autoComplete="off"
										className="w-100 h-auto position-relative bg-primary-light" />
									<Input.Password
										id="authLoginPasswordInput"
										name="loginEmail"
										src="@property.authPassword"
									placeholder={t('auth.authLoginPasswordInput.hint')}
									autoComplete="off"
										className="w-100 h-auto position-relative bg-primary-light mt-24px" />
								</Group>
								<Text
									id="loginButton"
									onClick={loginButtonOnClick}
									className="w-100 h-auto border-radius-10px position-relative bg-primary py-8px mt-32px text-text-on-primary ff-auth_regular text-center" >
									{t('auth.loginButton')}
								</Text>
								<Text
									id="loginButtonProd"
									onClick={loginButtonProdOnClick}
									className="w-100 h-auto border-radius-10px position-relative bg-primary py-8px mt-32px text-text-on-primary ff-auth_regular text-center" >
									{t('auth.loginButtonProd')}
								</Text>
							</Group>
						</Group>
					</Group>
				</Group>
			</Group>
      </Auth_fullscreenWrapper>
    </>
  );
};
