// Custom imports:
import response from '../../assets/data/response.json';
import provider from '../../assets/data/provider.json';
import providers from '../../assets/data/providers.json';
import method from '../../assets/data/method.json';

export const IS_READONLY = {
  // Readonly databases:
};

export const DATABASES = {
  INITIAL_STATE: {
    database: {
      // Databases:
			response,
			provider,
			providers,
			method,
    },
  },

  SET: 'DATABASES_SET',
};

export const DatabasesReducer = (draft, action) => {
  const { payload, type } = action;
  switch (type) {
    case DATABASES.SET:
      draft.database = {
        ...draft.database,
        ...payload,
      };
      break;

    default:
      return draft;
  }
};
