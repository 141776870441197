import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Main_wrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Image, Text, Icon, List, Card } from '../components';
import { useEffect } from 'react';
import icon_512 from '../assets/icon/icon_512.png';
import isotype from '../assets/ico/isotype.svg';
import { useTranslation } from 'react-i18next';

export const ApiView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const apiParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( apiParams, [])
	const apiOnLoaded = (options) => {
		run([{"id":"request1676933288473","function":"request","url":"(@cookie.api_url)\/providers","headers":{"Authorization":"Bearer (@cookie.bearer)"},"method":"get","into":"@database.providers","response":"json-api"}], options)
	}
	useEffect(apiOnLoaded, [])
	const providerCardOnClick = (options) => {
		run([{"id":"set1676975291864","function":"set","what":"@cookie.provider","value":"@element.providerList.value"},{"id":"set1676934156294","function":"set","what":"@cookie.providerName","value":"@cookie.provider.name"},{"id":"set1676977842125","function":"set","what":"@cookie.providerID","value":"@cookie.provider.uid"},{"id":"request1676988445227","function":"request","url":"(@cookie.api_url)\/providers\/(@cookie.providerName)","headers":{"Authorization":"Bearer (@cookie.bearer)"},"method":"get","into":"@database.provider","response":"json-api","onsuccess":[{"id":"goto1676988450392","function":"goto","view":"api_provider"}]}], options)
	}
	const addProviderButtonOnClick = (options) => {
		run([{"id":"set1676935429245","function":"set","what":"@property.action","value":"new_provider"},{"id":"popup1676935467878","function":"popup","dialog":"inputDialog"}], options)
	}
	const icon1677079714946OnClick = (options) => {
		run([{"id":"goto1677079714946","function":"goto","view":"auth_logout"}], options)
	}
	const image1676990296997OnClick = (options) => {
		run([{"id":"goto1676990381013","function":"goto","view":"home"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('api.title')}</title>
      </Helmet>
      <Main_wrapperWrapper>
          
			<Group
				id="apiBody"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative scrollable" >
				<Group
					id="group1676990296997"
					direction="horizontal"
					className="w-100 h-auto position-relative bg-light p-24px" >
					<Image
						id="image1676990296997"
						onClick={image1676990296997OnClick}
						src={isotype}
						className="w-48px h-auto position-relative" />
					<Text
						id="text1676994739214"
						tag="h3"
						className="w-fill h-auto valign-center position-relative ms-24px ff-titles" >
						{t('api.text1676994739214')}
					</Text>
					<Icon
						id="icon1677079714946"
						onClick={icon1677079714946OnClick}
						src="logout"
						className="w-auto h-auto align-end valign-center position-relative m-16px"
					/>
				</Group>
				<Group
					id="providersGroup"
					direction="vertical"
					className="w-100 h-auto position-relative p-48px" >
					<Group
						id="apiTitleBar"
						direction="horizontal"
						className="w-100 h-auto position-relative" >
						<Text
							id="providersTitle"
							tag="h1"
							className="w-auto h-auto valign-center position-relative" >
							{t('api.providersTitle')}
						</Text>
						<Icon
							id="addProviderButton"
							onClick={addProviderButtonOnClick}
							src="plus-circle"
							className="w-auto h-auto valign-center position-relative ms-16px"
						/>
					</Group>
					<List
						id="providerList"
						direction="auto"
						src="@database.providers"
						order={{"type":"asc","field":"label"}}
						columns={"auto"}
						className="w-100 h-auto position-relative mt-32px" >
						<Card
							id="providerCard"
							onClick={providerCardOnClick}
							className="max-w-500px min-w-280px w-auto h-auto position-relative me-16px mb-16px" >
							<Card.Body>
									<Image
										id="providerIcon"
										fallback={icon_512}
										src="@field.urlLogo"
										className="w-72px h-auto position-relative" />
									<Text
										id="providerName"
										tag="h3"
										className="w-100 h-auto position-relative mt-16px" >
										{t('api.providerName')}
									</Text>
									<Text
										id="providerDescription"
										className="w-100 h-auto position-relative mt-8px text-primary-text" >
										{t('api.providerDescription')}
									</Text>
							</Card.Body>
						</Card>
					</List>
				</Group>
			</Group>
      </Main_wrapperWrapper>
    </>
  );
};
