import React, { createContext, useEffect } from 'react';
import { useImmerReducer } from 'use-immer';
import { DatabasesReducer, DATABASES, IS_READONLY } from './reducers';
import { useDexie } from '../hooks';
import { condition, parse } from '../util';
import { v4 as uuidv4 } from 'uuid';

export const DatabasesContext = createContext({});

export const DatabasesProvider = (props) => {
  const { add, get } = useDexie();
  const [state, dispatch] = useImmerReducer(
    DatabasesReducer,
    DATABASES.INITIAL_STATE
  );
  const { database: databaseFromReducer = {} } = state;
  let database = databaseFromReducer._clone();

  useEffect(() => {
    let mounted = true;
    (async () => {
      if (!mounted) {
        return;
      }

      const databases = (await get()).reduce(
        (db, { id, data }) => (IS_READONLY[id] ? db : { ...db, [id]: data }),
        {}
      );
      mounted && dispatch({ type: DATABASES.SET, payload: { ...databases } });
    })();
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    (async () => {
      if (!mounted) {
        return;
      }

      await Promise.all(
        Object.keys(database).map(
          (id) =>
            !IS_READONLY[id] &&
            add({ id, data: database[id] }, { merge: false })
        )
      );
    })();
    return () => {
      mounted = false;
    };
  }, [databaseFromReducer]);

  const addToDatabase = async ({ key, to, what }) => {
    let steps = to.replace('@database.', '').split('.');

    // Get the database
    const databaseID = steps[0];
    // Remove the first step
    steps.shift();

    // Recorrer los nodos de la ruta y crearlos si no existen
    let data =database?.[databaseID]?._clone() || {};
    let currentObj = data;
    for (let i = 0; i < steps.length; i++) {
      const node = steps[i];
      if (!currentObj.hasOwnProperty(node)) {
        currentObj[node] = {};
      }
      currentObj = currentObj[node];
    }
    if( typeof currentObj === 'object' ){
      if( key === null ){
        key = uuidv4();
      }
      currentObj[key] = what;
    }else if( Array.isArray(currentObj) ){
      currentObj.push( what );
    }
    database[databaseID] = data;
    dispatch({ type: DATABASES.SET, payload: { [databaseID]: data } });
  };

  const deleteFromDatabase = async (what) => {
    const keys = what.replace('@database.', '').split('.');
    const id = keys.shift();

    if (IS_READONLY[id]) {
      return;
    }

    const lastKey = keys.pop();
    let data = database?.[id]?._clone() || {};

    const str = keys.length ? `data['${keys.join("']?.['")}']` : 'data';
    const obj = eval(str);
    if (Array.isArray(obj)) {
      eval(`${str}.splice(${lastKey},1)`);
    } else {
      eval(`delete ${str}['${lastKey}']`);
    }
    dispatch({ type: DATABASES.SET, payload: { [id]: data } });
  };

  const getFromDatabase = async (what, { filter, order, limit }) => {
    const keys = what.replace('@database.', '').split('.');
    const id = keys.shift();
    let data = database?.[id] || {};
    try {
      data = keys.reduce((data, key) => data[key], data);
      if (typeof data != 'object') {
        return parse(data);
      }
      data = Object.entries(data);
      if (filter) {
        data = data.filter(([, data]) => condition(filter, { data }));
      }
      if (order) {
        data = data.sort(([, value1], [, value2]) => {
          if (order.type === 'desc') {
            return (value1[order.field] || value1) >
              (value2[order.field] || value2)
              ? -1
              : 1;
          }
          return (value1[order.field] || value1) <
            (value2[order.field] || value2)
            ? -1
            : 1;
        });
      }
      if (limit) {
        data = data.slice(0, limit);
      }
      data = data.reduce(
        (data, [key, value]) => ({ ...data, [key]: value }),
        {}
      );
    } catch (e) {}
    return parse(data);
  };

  const setToDatabase = async ({ what, value, doDispatch = true }) => {
    const keys = what.replace('@database.', '').split('.');
    const id = keys.shift();

    if (IS_READONLY[id]) {
      return;
    }

    let data = database?.[id]?._clone() || {};
    const databaseStr = keys.length
      ? `database[id]['${keys.join("']['")}']`
      : 'database[id]';
    const str = keys.length ? `data['${keys.join("']['")}']` : 'data';
    try {
      eval(`${databaseStr}=value`);
      eval(`${str}=value`);
    } catch (error) {
      const whatArr = what.split('.');
      whatArr.pop();
      const parentWhat = whatArr.join('.');
      await setToDatabase({ what: parentWhat, value: {}, doDispatch: false });
      await setToDatabase({ what, value });
      return;
    }
    doDispatch && dispatch({ type: DATABASES.SET, payload: { [id]: data } });
  };

  return (
    <DatabasesContext.Provider
      value={{
        ...state,
        addToDatabase,
        deleteFromDatabase,
        getFromDatabase,
        setToDatabase,
      }}
    >
      {props.children}
    </DatabasesContext.Provider>
  );
};

export const DatabasesConsumer = DatabasesContext.Consumer;
export default DatabasesContext;
