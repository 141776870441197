import React from 'react';
import Component from './Component';
import { useSource } from '../hooks';

export const Web = ({ src, ...props }) => {
  const { itemData: data } = props;
  const { value } = useSource(src, { data });
  return <Component {...props} src={value} component="iframe" />;
};

Web.displayName = 'Web';
export default Web;
